import React from 'react';

const Solutions: React.FC = () => {
  return (
    <section id="solutions" className="solutions">
      <h2>Our Solutions</h2>
      <div className="solution-grid">
        <div className="solution-card">
          <h3>Smart Eldercare</h3>
          <ul>
            <li>In-bed detection and monitoring</li>
            <li>Sleep tracking and quality assessment</li>
            <li>Bathroom visit analysis</li>
            <li>Fall detection in critical areas</li>
          </ul>
        </div>
        <div className="solution-card">
          <h3>Intelligent Building</h3>
          <ul>
            <li>Real-time space utilization tracking</li>
            <li>Historical usage trend analysis</li>
            <li>Space optimization recommendations</li>
            <li>Integration of Sensing AI, Behavioral AI, and Spatial AI</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Solutions;